import { useEffect, useState, type ComponentType } from 'react'
import Head from 'next/head'
import { CacheProvider, Global } from '@emotion/react'
import { PaperCutThemeProvider } from '@papercutsoftware/pcds-react'
import { useSearchParams } from 'next/navigation'
import createEmotionCache from '../utils/createEmotionCache'
import { getProduct, ProductContext } from '@/context/product'
import { AuthFlow, AuthFlowContext } from '@/context/authflow'
import { productIDQueryParam } from '@/utils/pageurl/pageurl'
import { globalStyles } from '@/styles/global.styles'

const clientSideEmotionCache = createEmotionCache()

interface AppProps {
  Component: ComponentType<{ error?: Error }>
  pageProps: Record<string, unknown>
  error?: Error
}

const App = ({ Component, pageProps, error }: AppProps) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const searchParams = useSearchParams()
  const productID = searchParams.get(productIDQueryParam) ?? ''
  const authFlow = searchParams.has('signup') ? AuthFlow.Signup : AuthFlow.Login
  const product = getProduct(productID)

  useEffect(() => {
    const style = document.getElementById('critical-css')
    if (style) {
      style.parentNode?.removeChild(style)
    }
    setIsLoaded(true)
  }, [])

  return (
    <>
      <Head>
        <title>{product.getName()}</title>
        <link rel="icon" href="https://cdn.papercut.com/web/img/icon/favicon.ico" type="image/png" />
        <link rel="icon" href="https://cdn.papercut.com/web/img/icon/favicon@16.png" type="image/png" sizes="16x16" />
        <link rel="icon" href="https://cdn.papercut.com/web/img/icon/favicon@32.png" type="image/png" sizes="32x32" />
        <link
          rel="icon"
          href="https://cdn.papercut.com/web/img/icon/favicon@192.png"
          type="image/png"
          sizes="192x192"
        />
      </Head>
      <Global styles={globalStyles} />
      <CacheProvider value={clientSideEmotionCache}>
        <PaperCutThemeProvider themeName="DEFAULT">
          <ProductContext.Provider value={product}>
            <AuthFlowContext.Provider value={authFlow}>
              {isLoaded ? <Component {...pageProps} error={error} /> : null}
            </AuthFlowContext.Provider>
          </ProductContext.Provider>
        </PaperCutThemeProvider>
      </CacheProvider>
    </>
  )
}

export default App
