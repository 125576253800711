import { ErrorCodes } from '@/utils/errorCodes'
import { Product } from '@/context/product'

export const productIDQueryParam = 'product'
export const tenantIDQueryParam = 'tenantId'
export const prefilledEmailAddressQueryParam = 'prefilledEmail'
export const productPostLoginUrlQueryParam = 'redirectUrl'
export const errorQueryParam = 'error'

export class PageUrlBuilder {
  protected readonly queryParams
  private readonly urlPath: string
  private readonly product: Product
  private isRelativeUrl = true

  constructor(urlPath: string, product: Product) {
    this.queryParams = new URLSearchParams()
    this.queryParams.set(productIDQueryParam, product.getId())
    this.urlPath = urlPath
    this.product = product
  }

  withError(error: ErrorCodes): PageUrlBuilder {
    if (error) {
      this.queryParams.set(errorQueryParam, error)
    }

    return this
  }

  withTenant(tenantID: string): PageUrlBuilder {
    if (tenantID) {
      this.queryParams.set(tenantIDQueryParam, tenantID)
    }

    return this
  }

  withPrefilledEmailAddress(emailAddress: string): PageUrlBuilder {
    if (emailAddress) {
      this.queryParams.set(prefilledEmailAddressQueryParam, emailAddress)
    }

    return this
  }

  withProductPostLoginUrl(): PageUrlBuilder {
    this.queryParams.set(productPostLoginUrlQueryParam, this.product.getRedirectUrl())

    return this
  }

  absoluteUrl(): PageUrlBuilder {
    this.isRelativeUrl = false

    return this
  }

  build(): string {
    const relativeUrl = '/' + this.urlPath + '?' + this.queryParams.toString()
    if (this.isRelativeUrl) {
      return relativeUrl
    }
    const origin = typeof window !== 'undefined' ? window.location.origin : ''

    return origin + relativeUrl
  }
}
