import { createContext } from 'react'
import { productIDQueryParam, productPostLoginUrlQueryParam } from '@/utils/pageurl/pageurl'
import { isLocalHost } from '@/utils/localhost'

export class Product {
  private readonly name: string
  private readonly id: ProductID
  private readonly termsOfServiceUrl: string
  readonly productUrlProvider: ProductUrlProvider

  constructor(id: ProductID, name: string, tosUrl: string, productUrlProvider: ProductUrlProvider) {
    this.id = id
    this.name = name
    this.termsOfServiceUrl = tosUrl
    this.productUrlProvider = productUrlProvider
  }

  getId(): ProductID {
    return this.id
  }

  getRedirectUrl(): string {
    return this.productUrlProvider.get()
  }

  getName(): string {
    return this.name
  }

  getTermsOfServiceUrl(): string {
    return this.termsOfServiceUrl
  }
}
interface ProductUrlProvider {
  get(): string
}

export const unsetProductId = 'papercut'

export type ProductID = 'pocket' | 'hive' | 'papercut'

export const getProduct = (id: string): Product => {
  if (id == 'pocket') {
    return new Product(
      id,
      'PaperCut Pocket',
      'https://pocket.papercut.com/terms-of-service',
      new QueryParamProvider(pocketAllowedRedirectUrls),
    )
  }
  if (id == 'hive') {
    return new Product(
      id,
      'PaperCut Hive',
      'https://hive.papercut.com/terms-of-service',
      new QueryParamProvider(hiveAllowedRedirectUrls),
    )
  }
  if (id == '') {
    return noProduct
  }

  console.warn(`unsupported product: '${id}'`)

  return noProduct
}

export class QueryParamProvider implements ProductUrlProvider {
  private readonly allowedUrls: string[]
  overrideUrlParams: URLSearchParams | undefined
  queryParamName = productPostLoginUrlQueryParam

  constructor(allowedUrls: string[]) {
    this.allowedUrls = allowedUrls
  }

  get(): string {
    let urlParams = new URLSearchParams(window?.location.search)
    if (this.overrideUrlParams) {
      urlParams = this.overrideUrlParams
    }
    const redirectUrl = urlParams?.get(this.queryParamName) ?? ''

    // because we get it from the query params, it is untrusted
    if (!isAllowedRedirectUrl(redirectUrl, this.allowedUrls)) {
      throw new Error(`disallowed product redirect URL '${redirectUrl}'`)
    }

    return redirectUrl
  }
}

const isAllowedRedirectUrl = (redirectUrl: string, allowedRedirectUrls: string[]) => {
  // might be custom scheme
  const isCustomScheme = redirectUrl.startsWith('pmitcmobile://')
  if (!isCustomScheme) {
    let url
    try {
      url = new URL(redirectUrl)
    } catch (e) {
      throw new Error(`invalid redirect URL '${redirectUrl}': ${e}`)
    }
    redirectUrl = url.origin + url.pathname // strip out the query params
  }

  return allowedRedirectUrls.includes(redirectUrl) || isLocalHost(redirectUrl)
}

const pmitcMobileAppAllowedRedirectUrls = [
  'pmitcmobile://papercut/authenticating',
  'https://pcut.app/authenticating',
  'https://au-test.pcut.app/authenticating',
  'https://au-staging.pcut.app/authenticating',
  'https://au.pcut.app/authenticating',
  'https://eu.pcut.app/authenticating',
  'https://uk.pcut.app/authenticating',
]

const pocketAllowedRedirectUrls = [
  'https://pocket.papercut.com/authenticating',
  'https://au.pocket.papercut.com/authenticating',
  'https://uk.pocket.papercut.com/authenticating',
  'https://eu.pocket.papercut.com/authenticating',
  'https://au-staging.pocket.papercut.software/authenticating',
  'https://au-test.pocket.papercut.software/authenticating',
  ...pmitcMobileAppAllowedRedirectUrls,
]

const hiveAllowedRedirectUrls = [
  'https://hive.papercut.com/authenticating',
  'https://au.hive.papercut.com/authenticating',
  'https://uk.hive.papercut.com/authenticating',
  'https://eu.hive.papercut.com/authenticating',
  'https://au-staging.hive.papercut.software/authenticating',
  'https://au-test.hive.papercut.software/authenticating',
  ...pmitcMobileAppAllowedRedirectUrls,
]

const noProduct: Product = new Product(
  unsetProductId,
  'PaperCut',
  '',
  new QueryParamProvider([...pocketAllowedRedirectUrls, ...hiveAllowedRedirectUrls]),
)

// A product bound page URL is one that both contains the product and redirectUrl for the product query params
export const getProductFromProductBoundPageUrl = (
  pageUrl: URL,
  productPostLoginUrlQueryParamName?: string,
): Product => {
  const productID = pageUrl.searchParams.get(productIDQueryParam) ?? ''
  const product = getProduct(productID)
  if (product.productUrlProvider instanceof QueryParamProvider) {
    const queryParamProvider = product.productUrlProvider as QueryParamProvider
    queryParamProvider.overrideUrlParams = pageUrl.searchParams
    if (productPostLoginUrlQueryParamName) {
      queryParamProvider.queryParamName = productPostLoginUrlQueryParamName
    }
  }

  return product
}

export const ProductContext = createContext(noProduct)
