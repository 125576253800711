import { css } from '@emotion/react'
import { tokens } from '@papercutsoftware/pcds-react'

const { color } = tokens

export const globalStyles = css`
  html,
  body,
  #__next {
    font-family: Barlow, sans-serif;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: ${color.background.secondary};
    overscroll-behavior: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
  }
`
